import getConfig from 'next/config';
import { theme } from '@bitsoflove/pattyn-360/lib/styles';

const { publicRuntimeConfig = {} } = getConfig() || {};

export default {
  staticDir: publicRuntimeConfig.STATIC_DIR,
  bolFooterBg: '#000',
  bolFooterColor: '#a0a6ad',
  bolFooterGraphColor: '#007bff',
  status: {
    ...theme.status,
    default: {
      color: theme.colors.primary,
      background: theme.colors.brandLight,
    },
  },
  ...theme,
  colors: {
    ...theme.colors,
    dark: '#384347',
  },
};
